<template>
  <div class="col-12" align="left" @mouseleave="hoverValue = 0" id="starsPoll">
    <div style="display: inline-block; margin-left: auto; margin-right: auto;" :style="{'margin-right' : (position.option != 5) ? '8px' : '0px'}" v-for="(position) in questions.opciones" :key="position.id" @mouseenter="hoverValue = position.option" @click="$emit('input', position.option)">
      <i class="icons icon_star" v-show="hoverValue ? (hoverValue < position.option) : (value < position.option)"></i>
      <i class="icons icon_star_full" :style="{background: '#EFB810'}" v-show="hoverValue ? (hoverValue >= position.option) : (value >= position.option)"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: ['value', 'questions'],
  data () {
    return {
      hoverValue: 0
    };
  }
};
</script>
<style lang="scss">
#starsPoll {
  .icons {
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    display: flex;
    cursor: pointer;
  }
  .icon_star {
    -webkit-mask-image: url(../../assets/images/icons/polls/icon_star.svg);
    mask-image: url(../../assets/images/icons/polls/icon_star.svg);
    background: #A8ADB5;
    width: 16px;
    height: 16px;
  }
  .icon_star_full {
    -webkit-mask-image: url(../../assets/images/icons/polls/icon_star_full.svg);
    mask-image: url(../../assets/images/icons/polls/icon_star_full.svg);
    width: 16px;
    height: 16px;
  }
}
</style>
