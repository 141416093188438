<template>
  <div id="checkboxPoll">
    <div class="options_check" v-for="(position) in questions.opciones" :key="'ErdL' + position.id">
      <label class="container">
        <input type="checkbox" :name="position.option" :checked="position.option === value" @click="setValue(position.option)">
        <span class="checkmark"></span>
        <span class="texts_check ffr fw400">{{ position.option }}</span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: ['value', 'questions'],
  data () {
    return {
      hoverValue: null
    };
  },
  methods: {
    setValue (value) {
      if (this.hoverValue === value) {
        this.hoverValue = null;
      } else {
        this.hoverValue = value;
      }
      this.$emit('input', this.hoverValue);
    }
  }
};
</script>
<style lang="scss">
#checkboxPoll {
  display: flex;
  flex-wrap: wrap;
  .options_check {
    width: 50%;
    margin-top: 18px;
  }
  .texts_check {
    margin-top: 3px;
    font-size: 13px;
    color: #444B57;
  }
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 0.5px solid #444B57;
    border-radius: 4px;
    margin-top: 2px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 3px;
    top: 3px;
    width: 10px;
    height: 10px;
    background-color: #444B57;
    border-radius: 4px;
  }
}
</style>
