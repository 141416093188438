<template>
  <div id="radioQuestion">
    <div class="poll_head">
      <span class="question ffr fw500">
        {{languagePoll === 'es' ? this.questions.question : this.questions.questionEnglish}}*
      </span>
      <div class="cont_radio">
        <div v-for="(option, key) in options" :key="key" class="options">
          <b-radio
            class="radioOption"
            size="is-small"
            v-bind:name="'radio' + name"
            v-model="radioValue"
            :native-value="option"
            @input="setValue()"
          >
          </b-radio>
          <span class="radioText ffr fw400">{{ option }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['questions', 'languagePoll'],
  data () {
    return {
      options: [],
      subQuestions: [],
      radiosCont: 0,
      name: null,
      radioValue: null
    };
  },
  mounted () {
    this.options = this.languagePoll === 'es' ? this.questions.sub_option.split(',') : this.questions.subOptionsEnglish.split(',');
    this.radiosCont = this.options.length;
    this.name = this.questions.id;
  },
  methods: {
    setValue () {
      this.$emit('input', this.radioValue);
    }
  },
  watch: {
    languagePoll () {
      this.options = this.languagePoll === 'es' ? this.questions.sub_option.split(',') : this.questions.subOptionsEnglish.split(',');
    }
  }
};
</script>
<style lang="scss">
#radioQuestion {
  .question {
    font-size: 13px;
    color: #444b57;
  }
  .radioText {
    font-size: 13px;
    color: #444b57;
  }
  .options {
    width: 50%;
    display: flex;
    align-items: flex-start;
    margin-top: 18px;
  }
  .cont_radio {
    display: flex;
    flex-wrap: wrap;
  }
  .b-radio.radio:not(.button) {
    margin-right: 0;
    margin-top: 2px;
  }
  .b-radio.radio input[type=radio] + .check {
    border-radius: 4px;
    border: 0.5px solid #444B57;
    width: 18px;
    height: 18px;
  }
  .b-radio.radio input[type=radio] + .check:before {
    border-radius: 4px;
    background: #444B57;
    margin-left: calc(-18px * 0.5);
    margin-bottom: calc(-18px * 0.5);
    width: 18px;
    height: 18px;
  }
}
</style>
