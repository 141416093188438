<template>
  <div id="calendarPoll">
    <span class="question ffr fw500">
      {{languagePoll === 'es' ? this.questions.question : this.questions.questionEnglish}}*
    </span>
    <v-date-picker class="calendar_poll" v-model="pollResp" is-required>
      <template v-slot="{ inputValue, inputEvents }">
        <input
          class="input noborders"
          :value="inputValue"
          @input="setValue()"
          v-on="inputEvents"
          readonly="readonly"
          :placeholder="`${languagePoll === 'es' ? 'Escribe tu respuesta': 'Write your answer'}`"
        />
      </template>
    </v-date-picker>
  </div>
</template>
<script>
export default {
  props: ['questions', 'languagePoll'],
  data () {
    return {
      pollResp: null
    };
  },
  mounted () {},
  methods: {
    setValue (dateSend) {
      this.$emit('input', dateSend);
    }
  },
  watch: {
    pollResp () {
      const dateSend = this.$moment(this.pollResp).format('YYYY-MM-DD');
      this.setValue(dateSend);
    }
  }
};
</script>
<style lang="scss">
#calendarPoll {
  display: flex;
  flex-direction: column;
  input {
    background: #e0e2e2;
    height: 38px !important;
    width: 100%;
    color: #444b57;
    font-size: 10px !important;
    border: none;
  }
  input::placeholder {
    color: #757a86;
  }
  .calendar_poll {
    margin-top: 16px;
  }
  @media screen and (max-width:780px) {
    input {
    width: 100%;
  }
  }
}
</style>
