<template>
  <div id="openQuestion">
    <span class="question question__input ffr fw500">
      {{languagePoll === 'es' ? this.questions.question : this.questions.questionEnglish}}*
    </span>
    <b-field>
      <b-input
        v-model="pollResp"
        class="textField ffr fw400"
        maxlength="200"
        :has-counter="false"
        type="textarea"
        @input="setValue()"
        :placeholder="`${languagePoll === 'es' ? 'Escribe tu respuesta': 'Write your aswer'}`"
      ></b-input>
    </b-field>
  </div>
</template>
<script>
export default {
  props: ['questions', 'languagePoll'],
  data () {
    return {
      pollResp: null
    };
  },
  methods: {
    setValue () {
      this.$emit('input', this.pollResp);
    }
  }
};
</script>
<style lang="scss">
#openQuestion {
  .question {
    font-size: 13px;
    color: #444b57;
  }
  textarea {
    background: #e0e2e2;
    min-height: 60px;
    border-radius: 4px;
    border: none;
    color: #444B57;
    font-size: 10px !important;
    max-width: none;
    resize: none;
  }
  .textField {
    max-height: none !important;
    width: 100%;
    margin-top: 16px;
  }
  textarea::placeholder {
    color: #757a86;
  }
  @media screen and (max-width:780px) {
    .textField {
      width: 100%;
    }
    textarea {
      width: 100%;
      min-width: 100%;
    }
  }
}
</style>
